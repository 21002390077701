<template>
  <div class="doc-layout">
    <a-layout class="layout-container">
      <a-layout-header :style="{ width: '100%', position: 'fixed', zIndex: 9 }">
        <doc-header />
      </a-layout-header>
      <div class="layout" :style="{ marginTop: '48px', overflow: 'hidden' }">
        <div class="layout-sider" ref="sliderDrag">
          <doc-slider />
          <div class="drag-handle border-right" @mousedown="dragEagle"></div>
        </div>
        <div class="layout-content">
          <doc-content />
        </div>
      </div>
    </a-layout>
  </div>
</template>

<script>
import DocHeader from './components/DocHeader';
import DocContent from './components/DocContent';
import DocSlider from './components/DocSlider';
import { ENV, Storage } from '@/utils';
export default {
  name: 'AdminLayout',
  components: {
    DocHeader,
    DocSlider,
    DocContent,
  },
  created() {
    const defaultTheme = Storage.get(ENV.storage.theme);
    const value = defaultTheme ? defaultTheme.bg : 'light';
    window.document.documentElement.setAttribute('data-theme', value);
  },
  async mounted() {
    const { project_id, api_id } = this.$route.params;
    this.initCategoryList(project_id, api_id);
    // this.tokenAuth();
  },
  methods: {
    async initCategoryList(project_id, api_id) {
      const { data: projectInfo } = await this.$store.dispatch('getProjectInfo', project_id);
      await this.checkPrivate(projectInfo, api_id);
      const { data } = await this.$store.dispatch('getCategoryList', projectInfo);
      if (api_id) {
        data.forEach((category) => {
          const currentDoc = category.apiList.find(
            (item) => item.id === api_id
          );
          if (currentDoc) {
            this.$store.commit('setCurrentDoc', currentDoc);
          }
        });
      }
      this.$store.commit('changeLoading', false);
    },
    // 对于私有项目，需要验证当前用户是否可以访问
    async checkPrivate(projectInfo, api_id) {
      if (projectInfo && projectInfo.private) {
        const token = Storage.get(ENV.storage.token);
        if (!token) {
          const docPath = `/docs/${projectInfo.id}/${api_id}`;
          this.$router.push({ path: '/user/login', query: { redirect: docPath } });
        }
        if (token && !this.$store.state.auth) {
          const userInfo = await this.$store.dispatch('tokenAuth', token);
          // 不是团队成员
          if (!projectInfo.members.includes(userInfo.id)) {
            this.$message.error('抱歉，不是团队成员！');
            this.$router.push('/');
          }
        }
      }
    },
    async tokenAuth() {
      const token = Storage.get(ENV.storage.token);
      if (!token) {
        this.$router.push('/');
        return;
      }
      if (token && !this.$store.state.auth) {
        await this.$store.dispatch('tokenAuth', token);
      }
      // init project info
      if (this.$store.state.auth) {
        await this.$store.dispatch('getEnvList');
        await this.$store.dispatch('getTeamList');
        await this.$store.dispatch('getProjectList');
        await this.$store.dispatch('getCategoryList');
        this.$store.commit('changeLoading', false);
      }
    },
    dragEagle(e) {
      const maxWidth = 500,
        minWidth = 280;
      var targetDiv = this.$refs.sliderDrag;
      //得到点击时该地图容器的宽高：
      var targetDivWidth = targetDiv.offsetWidth;
      var startX = e.clientX;
      document.onmousemove = function(e) {
        e.preventDefault();
        //得到鼠标拖动的宽高距离：取绝对值
        var distX = Math.abs(e.clientX - startX);
        //往右方拖动：
        if (e.clientX > startX) {
          targetDiv.style.width = `${targetDivWidth + distX}px`;
        }
        //往左方拖动：
        if (e.clientX < startX) {
          targetDiv.style.width = `${targetDivWidth - distX}px`;
        }
        if (parseInt(targetDiv.style.width) >= maxWidth) {
          targetDiv.style.width = `${maxWidth}px`;
        }
        if (parseInt(targetDiv.style.width) <= minWidth) {
          targetDiv.style.width = `${minWidth}px`;
        }
      };
      document.onmouseup = function() {
        document.onmousemove = null;
      };
    },
  },
};
</script>

<style lang="scss">
.doc-layout {
  min-width: 1400px;
  height: 100%;

  .layout-container {
    height: 100%;
  }
  .ant-layout-header {
    height: 48px;
    line-height: 48px;
    background: #292929;
    color: #fff;
    padding: 0 10px;
  }
  .layout {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  .layout-sider {
    width: 330px;
    height: 100%;
    // border-right: 1px solid #f1f1f1;
    position: relative;

    .drag-handle {
      width: 5px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      cursor: col-resize;
      &:hover {
        border-color: #a6c5ed;
      }
    }
  }
  .layout-content {
    flex: 1;
    height: 100%;
  }
}
</style>
