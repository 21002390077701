<template>
  <div class="doc-slider">
    <div class="project-list">
      <div v-if="this.$store.state.currentProject" class="project-info">
        <div class="project-name">
          <span class="project-name-text">{{ this.$store.state.currentProject.name }}</span>
        </div>
        <!-- <div class="api-version">
          <a>{{ `Version ${this.$store.state.currentProject.version}` }}</a>
        </div> -->
      </div>
    </div>

    <div class="filter-wrapper">
      <a-input
        placeholder="搜索 名称、URL"
        @change="changeFilterKeyword"
        allowClear
      >
        <a-icon slot="prefix" type="search" />
      </a-input>
    </div>

    <doc-category-list />

  </div>
</template>

<script>
import DocCategoryList from './DocCategoryList.vue';
export default {
  name: 'AdminSlider',
  components: {
    DocCategoryList
  },
  data() {
    return {
      activeName: 'list',
    };
  },
  methods: {
    changeFilterKeyword(e) {
      this.$store.commit('setFilterKeyword', e.target.value);
    },
    changeSliderTab(key) {
      this.$store.commit('setSliderTabKey', key);
    },
  },
};
</script>

<style lang="scss">
.doc-slider {
  height: 100%;
  background-color: var(--slider-bg);
  position: relative;

  .helper-info {
    width: 100%;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .project-list {
    .project-info {
      display: flex;
      padding: 10px 20px;
      margin: 0;
      justify-content: space-between;
      line-height: 30px;
      border-bottom: 1px solid var(--border-color);
      .project-select {
        max-width: 90%;
        .ant-select-selection {
          background: none;
          border: none;
          box-shadow: none;
        }
        .ant-select-selection-selected-value {
          margin-right: 5px;
          color: var(--primary-color);
          font-size: 16px;
        }
        .ant-select-arrow {
          color: var(--primary-color);
        }
      }
      .project-name {
        flex: 1;
        font-size: 20px;
        text-align: center;
      }
      .api-version {
        width: 60px;
        font-size: 14px;
        a {
          color: #666;
          text-decoration: underline;
        }
      }
      .project-name-text {
        font-size: 16px;
        color: var(--primary-color);
      }
    }
  }

  .filter-wrapper {
    padding: 10px;
    .anticon {
      color: #c0c4cc;
    }
    .ant-input {
      border-radius: 15px;
    }
  }
}
</style>
