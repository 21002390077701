<template>
  <div class="doc-content">
    <div class="anchor">
      <a-anchor>
        <a-anchor-link href="#basic-info" title="基本信息" />
        <a-anchor-link
          href="#api-description"
          title="接口描述"
          v-if="docDetail && docDetail.description"
        />
        <a-anchor-link href="#request-params" title="请求参数">
          <a-anchor-link href="#request-header" title="header参数及说明" />
          <a-anchor-link href="#request-body" title="body参数及说明" />
        </a-anchor-link>
      </a-anchor>
    </div>
    <div class="preview-markdown" v-if="docDetail">
      <div class="section">
        <h2>{{ docDetail.name }}</h2>
        <p class="header-info border-bottom">
          <!-- <span>创建人：谜团</span>
          <span>最后更新：谜团</span> -->
          <span
            >创建时间：{{
              docDetail.create_time | moment('YYYY-MM-DD HH:mm:ss')
            }}</span
          >
          <span
            >更新时间：{{
              docDetail.update_time | moment('YYYY-MM-DD HH:mm:ss')
            }}</span
          >
        </p>
      </div>
      <div class="section">
        <h4 id="basic-info"><i class="maodian"></i>基本信息</h4>
        <ul class="editor-preview-info">
          <li>
            <strong>接口状态：</strong>
            <code>{{ API_STATUS_ENUM[docDetail.status] }}</code>
          </li>
          <li>
            <strong>接口URL：</strong> <code>{{ docDetail.url }}</code>
          </li>
          <li>
            <strong>请求方式：</strong> <code>{{ docDetail.method }}</code>
          </li>
          <li>
            <strong>Content-Type：</strong>
            <code>{{ docDetail.raw_content_type }}</code>
          </li>
        </ul>
      </div>
      <div class="section" v-if="docDetail && docDetail.description">
        <h4 id="api-description"><i class="maodian"></i>接口描述</h4>
        <div class="api-description">
          <mavon-editor
            v-model="content"
            defaultOpen="preview"
            :subfield="false"
            :editable="false"
            :toolbarsFlag="false"
          />
        </div>
      </div>
      <div class="section">
        <h4 id="request-params"><i class="maodian"></i>请求参数</h4>
        <div class="request-header" v-if="requestHeader.length">
          <h5 id="request-body">header参数及说明</h5>
          <ul>
            <li>
              <span><strong>KEY</strong></span>
              <span><strong>VALUE</strong></span>
              <span><strong>DESCRIPTION</strong></span>
            </li>
            <li v-for="item in requestHeader" :key="item.key">
              <span>{{ item.key }}</span>
              <span>{{ item.value }}</span>
              <span>{{ item.description }}</span>
            </li>
          </ul>
        </div>
        <div class="request-body">
          <h5 id="request-body">body参数及说明</h5>
          <editor
            v-model="requestBody"
            @init="editorInit"
            :options="readOnlyOptions"
            :lang="rawContentType"
            theme="eclipse"
            width="100%"
            height="300"
            ref="afterRequestEditor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_STATUS_ENUM, API_STATUS_OPYIONS } from '@/utils/constant';
export default {
  name: 'docContent',
  components: {
    editor: require('vue2-ace-editor'),
  },
  data() {
    return {
      API_STATUS_ENUM,
      API_STATUS_OPYIONS,
      requestHeader: [],
      requestBody: null,
      content: null,
      rawContentType: 'json',
      readOnlyOptions: {
        enableBasicAutocompletion: false, // 启用基本自动完成
        enableSnippets: false, // 启用代码段
        enableLiveAutocompletion: false, // 启用实时自动完成
        tabSize: 2, // 标签大小
        fontSize: 14, // 设置字号
        showPrintMargin: false, // 去除编辑器里的竖线
        showFoldWidgets: false, // 展示折叠
        readOnly: true, // 只读
      },
    };
  },
  computed: {
    docDetail() {
      const { currentDoc } = this.$store.state;
      this.requestHeader = currentDoc
        ? JSON.parse(currentDoc.header_params)
        : null;
      this.requestBody = currentDoc ? currentDoc.body_raw : null;
      this.content = currentDoc && currentDoc.description ? JSON.parse(currentDoc.description) : null;
      return currentDoc;
    },
  },
  methods: {
    editorInit: function(editor) {
      require('brace/ext/language_tools'); //language extension prerequsite...
      require('brace/mode/json');
      require('brace/theme/eclipse');
      require('brace/snippets/json');
    },
  },
};
</script>

<style lang="scss">
.doc-content {
  height: 100%;
  background-color: var(--content-bg);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  .anchor {
    width: 260px;
    height: 100%;
    position: fixed;
    top: 100px;
    right: 20px;
    z-index: 999;
  }
  .preview-markdown {
    margin: 0 300px 0 80px;
    padding: 20px;
    font-size: 14px;
    line-height: 1.8;
    padding-bottom: 80px;
    box-sizing: border-box;
    // width: calc(100% - 360px);
    // min-width: 726px;
    // max-height: calc(100% - 60px);

    h2 {
      margin: 0;
    }
    h4 {
      padding-left: 10px;
      font-weight: 500;
      position: relative;
      i {
        display: inline-block;
        position: absolute;
        width: 4px;
        height: 13px;
        top: 6px;
        left: 0;
        margin-right: 10px;
        background: #25b864;
      }
    }
  }

  .section {
    margin-bottom: 30px;
  }

  .header-info {
    padding: 10px 0;
    span {
      margin-right: 20px;
      font-size: 12px;
      color: #9e9e9e;
    }
  }
  .editor-preview-info {
    margin: 10px 20px;
    background-color: #f7f7f7;
    border-radius: 6px;
    padding: 8px 0;
    li {
      margin-left: 20px;
      padding-left: 4px;
      line-height: 28px;
      strong {
        display: inline-block;
        text-align: right;
        width: 100px;
        font-size: 12px;
      }
      code {
        font-style: normal;
        color: #e96900;
        padding: 2px 4px;
        font-size: 12px;
      }
    }
  }
  .request-header {
    margin: 10px 20px 20px;
    ul {
      li {
        display: flex;
        flex-direction: row;
        line-height: 30px;
        span {
          flex: 1;
        }
      }
    }
  }
  .api-description {
    margin: 10px 20px 20px;
  }
  .request-body {
    margin: 10px 20px 20px;
  }
}
</style>
