<template>
  <div class="doc-header">
    <a-row>
      <a-col :span="8">
        ApiMini 接口文档
      </a-col>
      <a-col :span="8">
        
      </a-col>
      <a-col :span="8">
        
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'docHeader',
  components: {},
  data() {
    return {
      currectProjectInfo: null
    };
  },
  computed: {
  },
  methods: {
    
  },
};
</script>

<style lang="scss">
.doc-header {
  height: 100%;
  
}
</style>
