<template>
  <div class="doc-category-list">

    <a-skeleton :loading="this.$store.state.loading">
      <!-- empty -->
      <a-empty v-if="!categoryMenu.length" />
      <!-- category -->
      <a-collapse v-else v-model="activeCategoryKey" @change="changeActivekey">
        <a-collapse-panel
          v-for="category in categoryMenu"
          :key="category.id"
          :class="currentItemId === category.id ? 'selected' : null"
        >
          <div slot="header">
            <a-icon type="folder" />
            <span class="margin-left">{{ category.name }}</span>
          </div>

          <!-- subCategory -->
          <a-collapse 
            v-if="category.children && category.children.length"
            v-model="activeCategoryKey"
            @change="changeActivekey"
          >
            <a-collapse-panel
              v-for="subCategory in category.children"
              :key="subCategory.id"
              :class="currentItemId === subCategory.id ? 'selected' : null"
            >
              <div slot="header">
                <a-icon type="folder" />
                <span class="margin-left">{{ subCategory.name }}</span>
              </div>

              <p
                v-if="subCategory.apiList.length"
                v-for="api in subCategory.apiList"
                :key="api.id"
                :class="['api-item-info', { 'selected' : currentItemId === api.id }]"
                @click="handleClickApiItem(api)"
              >
                <span :class="['method', api.method]">{{ api.method }}</span>
                <span class="name">
                  <a :title="api.name">{{ api.name }}</a>
                </span>
              </p>
            </a-collapse-panel>
          </a-collapse>
          <!-- subCategory end! -->

          <p
            v-if="category.apiList.length"
            v-for="api in category.apiList"
            :key="api.id"
            :class="['api-item-info', { 'selected' : currentItemId === api.id }]"
            @click="handleClickApiItem(api)"
          >
            <span :class="['method', api.method]">{{ api.method }}</span>
            <span class="name">
              <a :title="api.name">{{ api.name }}</a>
            </span>
          </p>
        </a-collapse-panel>
      </a-collapse>
    </a-skeleton>
  </div>
</template>

<script>
import { filterApiTree, ENV, Storage } from '@/utils';
export default {
  name: 'docCategoryList',
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      activeCategoryKey: Storage.get(ENV.storage.activeDocKey) || [],
      currentItemId: null, // category、subCategory、api
    };
  },
  computed: {
    categoryMenu() {
      const { loading, categoryTree, filterKeyword, currentDoc } = this.$store.state;
      if (currentDoc) {
        this.currentItemId = currentDoc.id;
      }
      if (loading) {
        return [];
      } else {
        if (filterKeyword === undefined) {
          return categoryTree;
        }
        const result = filterApiTree(categoryTree, filterKeyword);
        this.activeCategoryKey = result.ids;
        return result.tree;
      }
    },
  },
  methods: {
    changeActivekey(keys) {
      const ids = [...keys];
      const id = ids.length ? ids.pop() : null;
      Storage.set(ENV.storage.activeDocKey, keys);
      this.currentItemId = id;
    },
    handleClickApiItem(record) {
      const item = { ...record };
      this.currentItemId = item.id;
      this.$store.commit('setCurrentDoc', item);
    },
    queryApiDetail() {

    }
  },
};
</script>

<style lang="scss">
.doc-category-list {
  .list {
    padding: 0 10px;
  }
  .ant-collapse {
    background: none;
    border: none;
    .ant-collapse-item {
      border: none;
      .ant-collapse-header {
        padding: 9px 16px 9px 40px;
        i {
          color: #808080;
        }
        span {
          color: var(--primary-color);
        }
      }
      .ant-collapse-content-box {
        padding: 5px 0 0 16px;
      }
      .ant-collapse-content {
        border: none;
        background: none;
      }
      .ant-collapse
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow {
        color: #888;
      }
      .anticon-setting {
        color: #888;
      }

      &.selected > .ant-collapse-header {
        background-color: var(--highlight-background-color-secondary);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          background-color: var(--highlight-background-color-secondary);
        }
      }
      // &.ant-collapse-item-active > .ant-collapse-header {
      //   background-color: #ececec;
      //   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      //   &:hover {
      //     background-color: #ececec;
      //   }
      // }
      .ant-collapse-extra .extra-icon-wrapper {
        opacity: 0;
        &.ant-dropdown-open {
          opacity: 1;
        }
      }
      &.ant-collapse-item-active > .ant-collapse-extra .extra-icon-wrapper {
        opacity: 1;
      }
      .ant-collapse-header:hover {
        background-color: var(--hover-color);
        .ant-collapse-extra .extra-icon-wrapper {
          opacity: 1;
        }
      }
    }

    .ant-collapse-extra {
      position: absolute;
      top: 0;
      right: 0;
      .extra-icon-wrapper {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }
    }
  }

  .api-item-info {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0 0 0 10px;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    .method {
      margin: 0 10px;
      font-size: 10px;
      font-weight: bolder;
      transform: scale(0.8);
    }
    .name {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      a {
        color: var(--primary-color);
      }
    }
    .extra-icon-wrapper {
      opacity: 0;
      display: inline-block;
      width: 40px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      &.ant-dropdown-open {
        opacity: 1;
      }
    }
    &:hover {
      background-color: var(--hover-color);
      .extra-icon-wrapper {
        opacity: 1;
      }
    }
    &.selected {
      background-color: var(--highlight-background-color-secondary);
      &:hover {
        background-color: var(--highlight-background-color-secondary);
      }
    }
  }
}
.setting-dropdown {
  width: 150px;
  .ant-dropdown-menu-item {
    padding: 5px 15px;
  }
  a {
    i {
      margin-right: 10px;
    }
  }
}
</style>
